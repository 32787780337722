import Vue from 'vue'
import Vuex from 'vuex'
import { createVuexPersistedState } from "vue-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    orderAddressData: {}, // 地址数据
    orderData: {}, // 订单商品信息
    orderFreightData: {}, // 配送方式信息
  },
  getters: {
    getToken(state) {
      return state.user.token;
    },
    getNickname(state) {
      return state.user.nickname || "";
    }
  },
  mutations: {
    SET_USER(state, data) {
      state.user = Object.assign(state.user, data);
    },
    CLEAR_USER(state) {
      state.user = {};
    },
    SET_ORDER_ADDRESS_DATA(state, data) {
      state.orderAddressData = data;
    },
    SET_ORDER_DATA(state, data) {
      state.orderData = data;
    },
    SET_ORDER_FREIGHT_DATA(state, data) {
      state.orderFreightData = data;
    },
  },
  actions: {
    setUser(context, data) {
      context.commit('SET_USER', data)
    },
    clearUser(context) {
      context.commit('CLEAR_USER')
    },
  },
  plugins: [
    createVuexPersistedState({
      key: 'user',
      storage: window.localStorage,
      whiteList: [],
      blackList: [],
    }),
  ],
})
